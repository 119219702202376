import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql } from "gatsby"
import ResultCard from "../components/resultComponents/ResultCard"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"
import { AdserviceCompare } from "../components/CompareAdsense"

const FrontPage = ({ data }) => {
  const plansHook = data.allPlansJson.nodes
  const plans = plansHook.map(plan => {
    return (
      <ResultCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        calls={plan.callPrice}
        texts={plan.textPrice}
        internet={plan.internet}
        speed={plan.internetSpeed}
        openFee={plan.openFee}
        url={plan.url}
        border={true}
        pageName={"lapselle"}
      />
    )
  })

  return (
    <GlobalStyles>
      <MetaDetails
        title="Paras ensimmäinen puhelinliittymä lapselle 2020 - Katso täältä!"
        desc="Lapsen ensimmäinen puhelin tarvitsee myös puhelinliittymän, mutta minkälaisen? Katso täältä minkälainen puhelinliittymä sopii parhaiten juuri lapselle ja keneltä sen saa!"
      />
      <Container>
        <Header1>Paras puhelinliittymä lapselle</Header1>
        <TextArea>
          Lapselle riittää normaalisti puhelinliittymä hitaalla netillä ja
          monesti käytön mukaan veloitettava liittymä on sopivampi lapselle.
          Alapuolelta löydät sopivan liittymän sinun lapsellesi.
        </TextArea>
        <Header3>Valitse sopiva puhelinliittymä lapselle alapuolelta:</Header3>
        <AdserviceCompare />
        <RowContainer title={"Tutustu myös seuraaviin vertailuihin:"}>
          <Button
            link={"/ilman-nettia/"}
            title={"Puhelinliittymä ilman nettiä"}
          />
          <Button
            link={"/puhelinliittymavertailu/"}
            title={"Puhelinliittymävertailu"}
          />
          <Button link={"/mobiililaajakaista/"} title={"Mobiililaajakaista"} />
          <Button link={"/prepaid/"} title={"Prepaid"} />
        </RowContainer>
        <Header2>Miten valita puhelin ja liittymä lapselle?</Header2>
        <TextArea>
          Jos välillä tuntuu siltä, että puhelinliittymän valinta on vaikeaa
          itselle, niin sitä se on erityisesti jälkikasvulle. Lapselle valitun
          puhelimen ja liittymän taustalla on ennen kaikkea oma
          turvallisuudentunne siitä, että lapset ovat aina tavoitettavissa. Yhtä
          tärkeää on myös se, että on itsekin tavoitettavissa.
        </TextArea>
        <Header3>Miten valita puhelinliittymä lapselle?</Header3>
        <TextArea>
          Kun yhä useampien vanhempien mielestä alakoululaisillakin on hyvä olla
          oma älypuhelin, ovat tähän heränneet myös liittymävalmistajat tarjoten
          juuri lapsille ja perheille suunnattuja palveluja. Tärkein
          liittymävalintaan vaikuttava tekijä on tietysti oman jälkikasvun ikä.
          Alakoululaiselle lapselle on hyvä ostaa erilainen liittymä kuin
          teinille. Yksi hyvä vaihtoehto on ostaa lapselle prepaid-liittymä
          rajoittamattomalla määrällä viestejä ja puheluja. Netin käytön ja
          nuoren lapsen kehityksen kannalta voi olla hyvä juttu, että
          liittymässä on vain rajattu määrä dataa käytettävissä.
        </TextArea>
        <TextArea>
          Tällaisella liittymällä voit varmistua siitä, että lapsesi voi soittaa
          tai tekstata sinulle koska tahansa, mutta netin käyttö pysyy
          hallinnassa. Tämä voi olla erittäin hyvä vaihtoehto alakouluikäiselle
          lapselle, jonka älypuhelinkäytön ei tulisi viedä kaikkea vapaa-aikaa.
          Esimerkiksi 8-12-vuotiaat lapset pärjäävät hyvin ilman jatkuvaa netin
          käyttöä. Kun tullaan yläkouluun ja teini-ikään, on lapselle hyvä
          hankkia kiinteä mobiilinetti, sillä netissä tulee kuitenkin vietettyä
          aikaa, ja toisaalta erilaiset nettipohjaiset viestisovellukset pitävät
          vanhemmatkin kärryillä siitä, mitä teinin elämässä tapahtuu.
        </TextArea>
        <TextArea>
          Kannattaa myös ottaa huomioon liittymiä vertaillessa, että alle
          18-vuotiaat eivät voi solmia omia sopimuksiaan ilman huoltajan
          suostumusta, eli liittymä tulee huoltajan nimiin. Saldorajat
          helpottavat kuukausilaskujen maksamista, jos käytössä on liittymä,
          jossa on käytön mukaan laskutettava netti, puhelut ja viestit.
          Viimeistään yläasteella on hyvä ostaa nopeampi netti nuorelle, sillä
          nykyään älypuhelimia käytetään yhä enemmän videoiden ja giffien
          muodossa. Yhden YouTube-videon lataaminen voi haukata datapaketista 50
          megatavua. Ota huomioon nämä lapsen datankäyttöön liittyvät asiat:
          <ul>
            <li>
              Jo ensimmäisellä luokalla käytetään nettipohjaisia
              viestintäsovelluksia, kuten Whatsappia
            </li>
            <li>
              Videoista poiketen nettipohjaiset viestisovellukset eivät tarvitse
              useita gigatavuja dataa kuukaudessa, vaan perusliittymä riittää
            </li>
            <li>
              Datankäyttöä voidaan hallita myös operaattorin sivulta tai
              puhelimen kautta
            </li>
            <li>
              Jos sinua mietityttää lasten netin käyttö, voit hankkia
              sovelluksen, joka rajoittaa lasten netin käyttöä
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Liittymätyyppien vertailussa kannattaa kuunnella itse puhelimen
          käyttäjää eli lasta. Jos lapsella on hyvät syyt sille, minkä takia
          puhelinliittymässä tulisi olla juuri tietyt ominaisuudet, vanhempana
          paras tapa on hyväksyä toiveet ja tilata toiveita vastaava liittymä.
          Kuitenkaan lapsen puhelimessa ei tarvitse olla sitä markkinoiden
          nopeinta nettiä, vaan pienemmätkin tehot riittävät. Kaikki
          markkinoilla olevat mobiililaajakaistaliittymät käyttävät nykyään
          4G-verkkoja, joten 100 megan netti riittää tavalliseen netinkäyttöön
          ja videoiden lataamiseen enemmän kuin hyvin.
        </TextArea>
        <Header3>Erilaiset puhelinliittymät lapselle</Header3>
        <TextArea>
          Kun puhelinliittymävalinnassa on päästy siihen pisteeseen, että
          liittymiä aletaan vertailla lapselle tai nuorelle, kannattaa katsoa
          koko tarjontaa huolellisesti ja päättää vasta sitten, minkä
          liittymistä valitsee. Kaikilla Suomen matkapuhelinoperaattoreilla on
          todella kattava tarjonta erilaisia matkapuhelinliittymiä, jotka
          sopivat myös lasten ja nuorten tarpeisiin. Usein kätevin valinta
          laskutuksen kannalta on valita saman operaattorin liittymä, jota
          käyttää itse joko kotona kiinteän laajakaistaverkon yhteydessä tai
          omassa älypuhelimessa. Lähesty siis operaattoriasi ja pyydä liittymää
          lapselle, tarjouksella tietysti.
        </TextArea>
        <TextArea>
          Operaattorikohtaisesti tarkasteltuna esimerkiksi Telialla on
          valikoimissaan paljon lapsiystävällisiä liittymiä, joiden hinnat
          alkavat noin kahdeksasta eurosta kuukaudessa. Alakoululaiselle voi
          harkita ostavansa esimerkiksi Rajaton 2 M -liittymän, jossa on kahden
          megan rajaton netti. Tämä riittää hyvin netin viestisovellusten
          käyttöön. Lisäksi mukana tulee jonkin verran myös puheluita ja
          tekstiviestejä. Kyseinen liittymä maksaa kuukaudessa 14,90. DNA:lla on
          oma Koululle-kampanja, jonka yhteydessä operaattorilla on omia
          tarjouksia lapsille ja nuorille. Myös Moi Mobiilin edullisimmat
          liittymät soveltuvat hyvin lapsille.
        </TextArea>
        <TextArea>
          DNA:n Prepaid-liittymien aloitushinnat ovat 4,90 €, ja päiväkohtainen
          veloitus liittymällä on 0,89 euroa. Postpaid-liittymistä yhden
          megatavun netillä varustettu DNA Jämpti, jonka kuukausihinta on 9,90
          euroa. Nuorelle 100 megan netillä varustettu liittymä DNA Nopea 4G
          Jämpti maksaa 25,90 euroa kuukaudessa. Elisalla lapsille sopivia
          liittymiä ovat Saunalahti Tarkka Mini, jossa on 256 kilotavun netti ja
          joka on kuukausihinnaltaan todella edullinen, 7,90 euroa kuukaudessa.
          Nopeamman netin saa tilaamalla Saunalahti Tarkka Perus -liittymän,
          jossa on yhden megatavun netti. Tämä onkin oikein mainio
          puhelinliittymä lapselle.
        </TextArea>
        <TextArea>
          Liittymävalinnan yhteydessä on hyvä myös tarkastaa mahdolliset
          lisäpalvelut ja muut tuotteet, joista voi olla lapsen turvallisessa
          puhelimen ja netin käytössä hyötyä. Esimerkiksi Elisalta voi hankkia
          Turvapaketin, jonka avulla voi asettaa rajoituksia sekä lasten
          netinkäyttöön että erilliset aikarajat, joiden aikana älypuhelimen
          käyttö ei ole mahdollista. Lisäksi palvelu sisältää mahdollisuuden
          lisätä ikärajat haitallisille sivustoille. DNA:lla taas on
          valikoimissaan erillinen F-Securen kanssa kehitetty Turvapaketti, joka
          sisältää samojen ominaisuuksien lisäksi myös yleisen tietoturvan
          puhelimelle.
        </TextArea>
        <Header3>Mikä on hyvä puhelin lapselle?</Header3>
        <TextArea>
          Puhelinliittymän valinnan lisäksi päänvaivaa aiheuttaa tietenkin myös
          itse puhelimen valinta, ja etenkin suosituimpien merkkien älypuhelimet
          ovat aika suolaisen hintaisia. Esimerkiksi Samsungin, Huawein tai
          Applen lippulaivamallit maksavat 500 eurosta jopa tuhanteen euroon,
          eikä lapsen tai nuoren käsissä tällaisesta uuden tietokoneen
          arvoisesta puhelimesta ole välttämättä muutaman viikon jälkeen mitään
          jäljellä. Lapsille, kuten myös aikuisille, puhelin on
          viestintävälineen lisäksi valitettavasti myös statuskysymys, ja
          perheiden tuloerot voivat näkyä myös lapsen taskusta löytyvässä
          kännykässä.
        </TextArea>
        <TextArea>
          Tätä ei kuitenkaan kannata liikaa miettiä, sillä suurin osa
          vanhemmista ostaa lapsilleen hyvän peruspuhelimen, jonka saa noin
          sadan euron hintaan. Älypuhelimen saa hankittuna myös liittymän
          kylkiäiseksi kuluttomalla ja korottomalla osamaksulla, jonka voi
          valita esimerkiksi 12 kuukauden maksuajalla. Hyviä perusluokan
          puhelinmalleja ovat Samsungin edullisemmat Galaxy-malliset puhelimet,
          ja Huawein ja Honorin peruspuhelimet, jotka toimivat androidin
          käyttöjärjestelmällä. Lisäksi myös Nokialta on tullut hyviä
          Android-pohjaisia, korkeintaan pari sataa euroa maksavia laitteita,
          jotka ovat hyviä ensimmäisiä tai toisia puhelimia.
        </TextArea>
        <TextArea>
          Edullisin tämän kategorian älypuhelin lienee kirjoitushetkellä Honorin
          7S, jonka hinta Telian nettikaupassa on 69 euroa. Myös
          Verkkokauppa.comista, Gigantista ja Powerilta kannattaa tiedustella
          edullisia peruspuhelimia lapsille. Edullisin iPhone maksaa hieman alle
          300 euroa. Puhelimen ostamisen lisäksi kannattaa puhelimeen ostaa heti
          suojakuoret ja näyttökalvo, joka liimataan näyttöön kiinni. Nämä
          maksavat muutaman kympin, ja säästävät suurimmilta kolhuilta ja näytön
          naarmuuntumiselta. Puhelimen tippuminen, kolhiintuminen ja
          naarmuuntuminen nimittäin on lapsen elämässä ennemminkin sääntö kuin
          poikkeus.
        </TextArea>
        <TextArea>
          poikkeus. Jos ja kun vahinko tapahtuu ja puhelimen näyttö hajoaa tai
          puhelin esimerkiksi tippuu veteen, ei ensimmäisenä tarvitse mennä
          kauppaan hankkimaan uutta puhelinta, etenkin jos edellisen ostamisesta
          on vasta vähän aikaa. Suomessa on hyvä verkosto älypuhelinten
          perushuoltoa ja näyttöjenvaihtoa tekeviä yrityksiä, joilta kannattaa
          pyytää puhelimen korjausta. Esimerkiksi näytön korjaus maksaa muutaman
          kympin. Applen laitteet täytyy taas viedä huollettavaksi valtuutettuun
          huoltoliikkeeseen. IPhonen korjaaminen voi tulla takuun puolesta
          kalliiksi muualla kuin Applen valtuutetuissa liikkeissä.
        </TextArea>
        <Header2>Lasten puhelimen käyttö</Header2>
        <TextArea>
          Kun lapselle hankitaan ensimmäinen oma puhelin, on hyvä keskustella jo
          matkapuhelinoperaattorin tai muun alan ammattilaisen kanssa siitä,
          miten lapsen puhelinkokemuksesta saadaan hyvä ja turvallinen niin,
          että puhelimen käyttöympäristö sopii myös lapsille, mutta ei rajoita
          kuitenkaan puhelimen hyviä ominaisuuksia. Älypuhelin ei ole mikään
          ihan tavallinen lelu, vaan etenkin netin käytössä kannattaa olla
          tarkkana, jos kyse on ensimmäisestä puhelimesta. Netissä on
          kaikenlaista sisältöä, ja lapset ovat todella hyviä ja nokkelia
          oppimaan ja etenkin jakamaan sisältöä toisilleen.
        </TextArea>
        <TextArea>
          Mutta minkälainen sisältö lapsille sitten sopii, ja toisaalta kuinka
          paljon puhelinta voi käyttää päivittäin? Tämä riippuu ennen kaikkea
          lapsen iästä, ja nyrkkisääntönä kymmenvuotiaalle kannattaa
          nettiselauksen suodatusasetukset asettaa maksimiin. On lisäksi
          tärkeää, että vanhemmat käyttävät lasten kanssa puhelinta yhdessä,
          esimerkiksi pelien muodossa. Vaikka puhelimen käytön seuraaminen ei
          ole aina mahdollista koulupäivien aikana, on esimerkiksi lasten
          viestien lukeminen viestipalveluissa, kuten Whatsappissa aikuisten
          velvollisuus, ei vain mahdollisuus. Katso tästä, mitä muita asioita
          lapsen puhelinsuhteessa kannattaa ottaa huomioon:
          <ul>
            <li>
              Puhelin on parhaimmillaan silloin, kun se toimii sekä viestintään
              lasten kanssa että auttaa lasta sosiaalisten suhteiden
              rakentamisessa
            </li>
            <li>
              Suhtaudu lapsen puhelimenkäyttöön vakavasti, sillä samalla pohdit
              myös omaa puhelimen käyttöä
            </li>
            <li>
              Nettikiusaaminen on tosiasia. Kerro lapselle miten somessa ja
              Whatsappissa käyttäydytään
            </li>
            <li>
              Kaikkea haitallista sisältöä ei voi estää. Ole perillä siitä, mitä
              lapsi netissä touhuaa.
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Kuten myös aikuiset ihmiset tietävät, älypuhelin voi olla todella
          koukuttava väline. Koska puhelin koukuttaa aikuisenkin, se koukuttaa
          helposti myös lapsen. Siksi on tärkeää näyttää lapsille esimerkkiä
          puhelimen käytössä. Älä itse ota puhelinta esiin ruokapöydässä ja pidä
          puhelin lähtökohtaisesti taskussa kävellessä kadulla tai
          liikennevälineissä. Näin lapsetkin oppivat, että älypuhelin pysyy
          repussa silloin kun ollaan liikkeessä, ja ettei ruokailun aikana
          käytetä puhelinta. Toinen tärkeä puhelimen käyttöön liittyvä tapa on
          se, koska puhelin laitetaan pois ennen nukkumaan menemistä.
        </TextArea>
        <TextArea>
          Pienten lasten ei tarvitse nukkua älypuhelimen vieressä, eikä
          aikuistenkaan. Hyvä vinkki on tehdä omaan olohuoneeseen puhelinboksi,
          johon laitetaan tuntia tai paria ennen nukkumaanmenoa sekä omat että
          lasten kännykät. Näin koko perheellä on aikaa tehdä iltatoimet yhdessä
          ilman, että älypuhelimet häiritsevät. Tämä auttaa koko perhettä myös
          nukkumaan paremmin. Lasten puhelinten käyttöä rajoittavia sovelluksia
          on markkinoilla useita. Tällaisilla sovelluksilla lasten puhelimen
          käyttö ja pelaaminen pysyvät kohtuuden rajoissa. Sovelluksilla voi
          asentaa erilaiset ajat esimerkiksi pelipäiville ja viikonlopuille.
        </TextArea>
        <Header3>Parhaat sovellukset lasten puhelimen käyttöön</Header3>
        <TextArea>
          Ennen kuin annat puhelimen lapselle, kannattaa puhelin käydä läpi ja
          tehdä asetuksiin tarvittavat muutokset haitallisen puhelinkäytön
          estämiseksi. Esimerkiksi iPhonessa puhelimissa Asetukset-välilehdeltä
          voi selata PIN-koodilla turvattuja rajoituksia niistä nettisivuista
          tai nettisivuluokitteluista, joihin lapsilla ei pitäisi olla mitään
          asiaa. Voit myös lisätä kiellettyjen sivujen listalle sivuja erikseen.
          Asetuksista löytyy mahdollisuus rajoittaa sovellusten lataamista
          sovelluskaupoista, ja mahdollista on myös rajoittaa ostoksien
          tekemistä sovelluksissa, joka on todella näppärä toiminto esimerkiksi
          nykyisten pelien mikromaksukäytäntöjen yhteydessä.
        </TextArea>
        <TextArea>
          Jos puhelimessa on Android, kuten suurimmassa osassa lapsille
          tarkoitetuista puhelimista on, tilanne on hieman kinkkisempi, sillä
          Androidissa on edelleen aika rajatut mahdollisuudet erilaisille
          suodatuksille ja kielloille. Androidille on kuitenkin useita
          sovelluksia, joilla voi suodattaa haitallista sisältöä. Tällaisia
          sovelluksia löytyy esimerkiksi monilta tietoturvayrityksiltä, kuten
          Nortinilta ja F-Securelta. Esimerkiksi Nortonin Family Premier, joka
          on maksullinen sovellus, toimii todella hyvin rajoittaen pääsyä
          haitallisille sivuille. Uusimmissa Androideissa on ikärajakielto
          sovelluskaupalle, joka rajoittaa haitallisten sovellusten lataamista.
        </TextArea>
        <TextArea>
          Ruutuajan ja puhelimen käytön seuraamiseen tehtyjä sovelluksia on
          esimerkiksi suosittu ja hyväksi todettu Screen Time, jolla pystyy
          ensinnäkin tarkkailemaan lapsen ruutuaikaa, että myös tarpeen mukaan
          rajoittamaan sitä. Suomalainen sovellus lasten puhelimen käytön
          kontrolloimiseen on F-Securen Parental control. Sekä Nortonin että
          F-Securen sovelluksilla voi määrittää lisäksi erikseen sen, kuinka
          monta tuntia päivässä tiettyä sovellusta voidaan käyttää. Jotkut
          sovellukset myös voivat auttaa lasta esimerkiksi ulkoilemaan, antaen
          palkintona tunnin pihapeleistä puoli tuntia ruutuaikaa.
        </TextArea>
        <Header3>Mitä operaattoreita Suomessa toimii?</Header3>
        <TextArea>
          Kannattaa kuitenkin ottaa huomioon, että mitkään sovellukset tai
          ohjelmistot eivät korvaa asiasta puhumista ja pelisääntöjen yhteistä
          sopimista. On myös vanhempien vastuulla kieltää puhelimen käyttö ja
          ottaa tarvittaessa myös puhelin pois lapselta, jos homma ei toimi.
          Vaikka sovelluksista on paljon hyötyä, ei siis kannata yrittää
          ulkoistaa kolmansille osapuolille oman lapsen puhelimen käytön
          ongelmia. Puhelimen käyttöön liittyvien sääntöjen sisäistäminen voi
          olla toki hyvin vaikeaa, koska teknologian kehittyessä puhelimista on
          tullut niin tärkeä osa elämää.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Kun hankit lapselle omaa puhelinta ja liittymää, valitse edullinen
          perusliittymä ja peruspuhelin. Alakouluikäinen lapsi ei tarvitse omaan
          puhelimeensa kaikista nopeinta nettiä, eikä tuhannen euron laitetta.
          Keskustele lisäksi lapsen kanssa aktiivisesti siitä, miten puhelinta
          käytetään.
        </TextArea>
        <TextArea>
          Näin opetat hyvää puhelinkulttuuria ja vastavuoroisuutta lapsille jo
          alusta alkaen. Hyvän liittymän ja puhelimen hankinnan lisäksi lapsia
          tulee opettaa ja opastaa älypuhelimen käytössä, ja myös hyvällä
          tavalla valvoa, mitä siellä puhelimella oikein tapahtuu.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson(filter: { internetType: { nin: "4g" } }) {
      nodes {
        id
        benefits
        callPrice
        internet
        internetSpeed
        name
        price
        type
        url
        textPrice
        img
        openFee
      }
    }
  }
`

export default FrontPage
